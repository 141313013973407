import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ContactForm } from "@/components/contact-form";
import { Timeline } from "@/components/timeline";
import { Arrow } from "@/components/arrow";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";

const UIUXDesign = () => {
  return (
    <>
      <GatsbySeo
        title="UI Design Services | UX Design Services | UIUX Development"
        description="UI developers that provide our clients with exceptional services that would position them on the map to thrive in a transforming world."
        canonical="https://www.tectratechnologies.com/ui-ux-design/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/ui-ux-design/",
          title: "UI Design Services | UX Design Services | UIUX Development",
          description:
            "UI developers that provide our clients with exceptional services that would position them on the map to thrive in a transforming world.",
          site_name: "Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
            {
              "@type": "WebPage",
              "@id": "https://www.tectratechnologies.com/ui-ux-design/",
              url: "https://www.tectratechnologies.com/ui-ux-design/",
              name: "UI Design Services | UX Design Services | UIUX Development",
              isPartOf: {
                "@id": "https://www.tectratechnologies.com/#website",
              },
              datePublished: "2019-08-21T06:11:40+00:00",
              dateModified: "2021-07-14T08:53:23+00:00",
              description:
                "UI developers that provide our clients with exceptional services that would position them on the map to thrive in a transforming world.",
              breadcrumb: {
                "@id":
                  "https://www.tectratechnologies.com/ui-ux-design/#breadcrumb",
              },
              inLanguage: "en-US",
              potentialAction: [
                {
                  "@type": "ReadAction",
                  target: ["https://www.tectratechnologies.com/ui-ux-design/"],
                },
              ],
            },
            {
              "@type": "BreadcrumbList",
              "@id":
                "https://www.tectratechnologies.com/ui-ux-design/#breadcrumb",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: "https://www.tectratechnologies.com/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "UI/UX Design | User-Experience",
                },
              ],
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white h-screen flex items-center justify-center">
          <div className="w-full max-w-7xl mx-auto px-5 lg:px-8">
            <div className="text-left xl:w-3/5">
              <div className="">
                <h1 className="text-black text-4xl font-poppins font-semibold mb-4 normal-case leading-relaxed">
                  Advanced UX with structured approach to make business
                  impactful.
                </h1>
                <div className="relative flex overflow-hidden justify-start items-center text-center">
                  <Link
                    to="/contact"
                    className="flex items-center text-xl normal-case hover:text-black"
                    activeClassName="border-b-2 border-black"
                  >
                    Talk to us
                    <Arrow color="black" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-center text-2xl font-poppins font-semibold captalize">
              What makes our UI/UX services the best?
            </h2>
            <p className="max-w-5xl mx-auto text-center text-base text-black font-poppins font-thin leading-8 mt-4">
              With a fuse of technology &amp; trend, our UI/UX designer
              captivates you with elegant designs that stand out among others.
              Our vision is to keep a pair with modern technology and ongoing
              trend. Overall, we offer you:
            </p>
            <div className="mt-8">
              <div className="w-full h-full">
                <StaticImage
                  src="../../images/sads6tnlwqo7.png"
                  alt=""
                  layout="constrained"
                  placeholder="blurred"
                  quality={95}
                  height={500}
                  className="w-full h-60 md:h-92 lg:h-auto"
                  formats={["auto", "png"]}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 mt-8 lg:mt-12">
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    User-led Strategy
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Our clients not only get a unique design but our solutions
                    are driven by user-led strategies that ensure great
                    usability and high simplicity. All in all, we create
                    seamless and effective designs for your customers.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Wide Range Expertise
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Our team has created different types of software for a wide
                    range of companies. Thus, we have a clear picture in our
                    minds of different designs and smart solutions. Also, our
                    team keeps on track with trains to offer you the best.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Transparent &amp; Communicative
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Communication is the key to success. We have an open line
                    for communication to maintain transparency and project
                    visibility. Also, we organized weekly calls and meets with
                    our clients to update the status of our work timely.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Affordable &amp; Timely
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    With custom and innovative ideas, we provide you with an
                    affordable and timely solution to grow your business. We
                    make a strong base with close collaboration and ideologies
                    to ensure you the best ever experience in UI/UX.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Industry Expertise
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    With diverse expertise in providing premium UI UX solutions,
                    our team serves you with the best. We provide you with
                    solutions with the best visuals, content, and users friendly
                    designs. Our team also takes your suggestions in order to
                    give you the best exposure.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Customer Satisfaction
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Customer satisfaction is our priority and for this, we
                    bridge the communication gap. We always keep your brand's
                    mission and vision in our mind to deliver you the best. In
                    addition, we take your suggestions for designing the
                    outlook.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="relative overflow-hidden bg-white mt-10 lg:mt-12">
          <div className="mx-auto max-w-7xl px-0 lg:px-8 relative">
            <div className="grid grid-cols-1 items-center justify-center lg:gap-y-2 lg:grid-cols-12">
              <div className="flex flex-wrap items-center justify-center lg:px-0 pb-4 lg:pr-12 sm:mx-0 sm:pb-0 lg:col-span-6 order-2 lg:order-1">
                <div className="relative z-10 gap-x-4 px-5 lg:px-0 lg:block lg:gap-x-0 lg:gap-y-1">
                  <p className="text-base text-black font-poppins font-thin leading-8 mb-0">
                    We design highly usable and engaging digital products
                    putting customer needs at the core. While UI Designers build
                    an intuitive interface that is aesthetically appealing, the
                    UX Designers and UX Writers formulate personalized user
                    journeys that can be later analyzed for performance
                    measurement.
                  </p>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4 mb-0">
                    Using design principles like user attention span, peak-end
                    role, familiarity, user scan patterns, Fitt’s law, banner
                    blindness, cognitive tunneling, etc. we create designs that
                    customers fall in love with. At the same time, we ensure to
                    keep the application and product designs specific and
                    relevant to the niche. For example, healthcare applications
                    require easy and quick navigation rather than bright or
                    fancy interfaces; social media applications need to be
                    interactive and aesthetically appealing, and banking
                    applications can use design familiarity to enable high
                    usability. Our ability to understand the needs of our
                    client’s direct and indirect customers allows us to examine
                    and resolve customer issues before they even arise.
                  </p>
                </div>
              </div>
              <div className="lg:col-span-6 order-1 lg:order-2 mb-2 lg:mb-2">
                <div className="relative w-auto overflow-hidden lg:mt-0 lg:w-[67.8125rem]">
                  <div className="w-full h-full">
                    <StaticImage
                      src="../../images/UI1.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={350}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                  <div className="w-full h-full mt-4">
                    <StaticImage
                      src="../../images/ui-ux23.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={350}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              Process
            </h2>
            <div className="mt-6 ml-2 lg:ml-0">
              <Timeline
                items={[
                  "Understand: Initial exploration",
                  "Research the marketplace & competition",
                  "Sketch, analyze, repeat",
                  "Design & test with wireframes & prototypes",
                  "Develop and impement UI",
                ]}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default UIUXDesign;
